<template>
  <div class="recaptcha-container">
    <div ref="recaptcha" id="g-recaptcha"></div>
  </div>
</template>

<script>
export default {
  name: 'RecaptchaCheckbox',

  props: {
    siteKey: {
      type: String,
      default: () => process.env.VUE_APP_RECAPTCHA_SITE_KEY
    }
  },

  data() {
    return {
      widgetId: null
    }
  },

  mounted() {
    this.loadRecaptcha()
  },

  beforeUnmount() {
    if (this.widgetId !== null) {
      window.grecaptcha.reset(this.widgetId)
    }
  },

  methods: {
    loadRecaptcha() {
      if (!window.grecaptcha) {
        const script = document.createElement('script')
        script.src = `https://www.google.com/recaptcha/api.js?render=explicit`
        script.async = true
        script.defer = true
        script.onload = () => this.renderRecaptcha()
        document.head.appendChild(script)
      } else {
        this.renderRecaptcha()
      }
    },

    renderRecaptcha() {
      window.grecaptcha.ready(() => {
        this.widgetId = window.grecaptcha.render(this.$refs.recaptcha, {
          sitekey: this.siteKey,
          callback: this.onVerify,
          'expired-callback': this.onExpired
        })
      })
    },

    onVerify(token) {
      this.$emit('verified', token)
    },

    onExpired() {
      this.$emit('expired')
    }
  }
}
</script>

<style scoped>
.recaptcha-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
