<template>
  <form class="form" @submit.prevent="onSubmit">
    <InputFieldset>
      <InputField id="pay-amount" label="Amount" type="number" v-model="form.amount" step=".01" required />
    </InputFieldset>

    <InputFieldset>
      <InputField id="pay-email" label="Email" type="email" v-model="form.email" required />
      <InputField id="pay-company" label="Company" v-model="form.company" required />
      <InputField id="pay-invoice" label="Invoice #" v-model="form.invoice" placeholder="Invoice number(s)" required />
      <InputField id="pay-memo" label="Memo" v-model="form.memo" placeholder="Optional comments..." />
    </InputFieldset>

    <InputFieldset>
      <InputField id="pay-name" label="Name on Card" fieldStyle="width:auto;flex:1;" v-model="form.name" required />
      <InputField id="pay-zip" label="Billing Zip" fieldStyle="width:35%" v-model="form.zip" required />
      <InputEPay @card-ready="card = $event" />
    </InputFieldset>

    <RecaptchaCheckbox 
      @verified="recaptchaToken = $event"
      @expired="recaptchaToken = null"
    />

    <PayButton :disabled="!formValid || processing" type="submit">
      <span v-if="processing">
        <Spinner />
      </span>
      <span v-else-if="!formValid">
        Complete required (*) fields...
      </span>
      <span v-else>
        PAY ${{ totalCharge }}
        <span class="fee-math" v-if="totalCharge > form.amount">
          <br>
          (${{ form.amount }} + ${{ feeTotal }} fees)
        </span>
      </span>
    </PayButton>

    <div class="fee-note" v-if="feePercent || feeFixed">
      We charge a <span v-if="feePercent"> {{ feePercent * 100 }}%</span><span v-if="feePercent && feeFixed"> +</span> <span v-if="feeFixed"> ${{ feeFixed }}</span> fee for credit card payments.
    </div>
    <div class="processing-note">
      All credit card payments will show up as: Parker Corporate Services, Inc.
    </div>
  </form>
</template>

<script>
import { Client } from '../services/usaepay.service';

import PayButton from './Button.vue';
import InputFieldset from './Fieldset.vue';
import InputField from './InputField.vue';
import Spinner from './Spinner.vue';
import InputEPay from './InputEPay';
import RecaptchaCheckbox from './RecaptchaCheckbox.vue';

export default {
  components: {
    PayButton,
    InputField,
    InputFieldset,
    InputEPay,
    Spinner,
    RecaptchaCheckbox
  },

  data() {
    return {
      form: {
        amount: '',
        name: '',
        zip: '',
        company: '',
        email: '',
        invoice: '',
        memo: '',
        total: 0
      },
      card: undefined,
      processing: false,
      token: null,
      recaptchaToken: null
    }
  },

  computed: {
    formValid() {
      const f = this.form;
      return f.amount && f.name && f.company && f.invoice && f.zip && f.email && this.card;
    },
    totalCharge() {

      const amt = parseFloat(this.form.amount);

      // The user pays the transaction fees
      // @see https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
      // const charge = (amt + this.feeFixed) / (1 - this.feePercent);

      // The user pays a fee, but not exactly reverse math:
      // i.e. $100 @ 3% = $103.ºº
      const charge = (amt + (amt * this.feePercent)) + this.feeFixed;

      return charge.toFixed(2);
    },
    feePercent() {
      return parseFloat(process.env.VUE_APP_FEE_PERCENT) || 0;
    },
    feeFixed() {
      return parseFloat(process.env.VUE_APP_FEE_FIXED) || 0;
    },
    feeTotal() {
      return (parseFloat(this.totalCharge) - this.form.amount).toFixed(2);
    }
  },

  methods: {
    async onSubmit() {
      if (!this.formValid) return;

      this.processing = true;
      this.form.total = this.totalCharge;

      try {
        const token = await Client.getPaymentKey(this.card);
        await this.processPayment(token);
      } catch (error) {
        console.error(error);
        this.$emit('error', error);
      } finally {
        this.processing = false;
      }
    },

    processPayment(token) {
      this.token = token;

      const self = this;
      const API_ROOT = process.env.VUE_APP_API_ENDPOINT;

      return fetch(`${API_ROOT}/charge.php`, {
        method: 'POST',
        body: JSON.stringify({
          token,
          ...this.form,
          recaptchaToken: this.recaptchaToken
        })
      })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          self.$emit('error', data.error);
          this.processing = false;
        } else {
          const charge = {
            email: data.fields.email,
            company: data.fields.company,
            description: data.fields.memo,
            amount: data.charge.auth_amount,
            key: data.charge.key,
          }

          self.$emit('complete', charge);
        }
      })
      .catch((err) => {
        self.$emit('error', err.message || 'Something went wrong. Please try again or let us know if the problem persists.');
        this.processing = false;
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.form {
  margin-bottom: 40px;
}
.fee-note {
  color: rgba(0,0,0,.5);
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}
.fee-math {
  font-weight: normal;
  font-size: .8rem;
}
.processing-note {
  color: rgba(0,0,0,.5);
  font-size: 12px;
  font-weight: normal;
  margin-top: 20px;
  text-align: center;
}
</style>
