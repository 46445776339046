<template>
  <button
    class="btn"
  >
    {{ text }}
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
.btn {
    background: #63A84B;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    padding: 8px 12px;
    text-align: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    transition: all .2s ease-in-out;

    &:hover {
      background: lighten(#63A84B, 5%);
    }

    &[disabled],
    &[disabled]:hover {
        background: #63A84B;
        opacity: .7;
        cursor: not-allowed;
        pointer-events: none;
    }
}
</style>
